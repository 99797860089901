  <main *ngIf="!this.editing" role="main">
    <div class="container">
      <!-- ROW -->
      <div class="row mb-3">
        <div class="col-12">
          <!-- SCHOOL GALLERY COMPONENT -->
          <app-escola-gallery-new style="height: 100%" (clickMessageLink)="this.requestInfo('gallery')" #escolaGallery [escola]="this.escola" *ngIf="escola" [editing]="this.editing"></app-escola-gallery-new>
          <!-- END SCHOOL GALLERY COMPONENT -->

          <!-- SCHOOL INFO MOBILE -->
          <app-escola-info [isMobile]="true" [escola]="this.escola" [reviews]="this.reviews" *ngIf="escola"></app-escola-info>
          <!-- END SCHOOL INFO MOBILE -->
        </div>
      </div>
      <!-- END ROW -->

      <!-- ROW -->
      <div class="row mb-5">
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 order-xxl-1 order-xl-1 order-lg-1 order-md-1 order-sm-2 order-2">
          <!-- SCHOOL INFO -->
          <app-escola-info [isMobile]="false" [escola]="this.escola" [reviews]="this.reviews" [familyChoice]="this.familyChoice" *ngIf="escola"></app-escola-info>
          <!-- END SCHOOL INFO -->

          <!-- O QUE ESTÃO FALANDO -->
          <app-escola-sobre [escola]="this.escola" *ngIf="escola"></app-escola-sobre>
          <!-- END O QUE ESTÃO FALANDO -->

          <!-- PRINCIPAIS CARACTERÍSTICAS -->
          <app-escola-caracteristicas [escola]="this.escola" [reviews]="this.reviews" *ngIf="escola"></app-escola-caracteristicas>
          <!-- END PRINCIPAIS CARACTERÍSTICAS -->

          <!-- DEPOIMENTOS -->
          <app-escola-depoimentos [escola]="this.escola" [reviews]="this.reviews" *ngIf="escola"></app-escola-depoimentos>
          <!-- END DEPOIMENTOS -->

          <!-- VISÃO DO ESPECIALISTA -->
          <app-escola-visao-especialista [escola]="this.escola" *ngIf="escola"></app-escola-visao-especialista>
          <!-- END VISÃO DO ESPECIALISTA -->

          <!-- PERFIL DA ESCOLA -->
          <app-escola-perfil [escola]="this.escola" *ngIf="escola"></app-escola-perfil>
          <!-- END PERFIL DA ESCOLA -->

          <!-- QUALIFICAÇÕES DA ESCOLA -->
          <app-escola-qualificacoes [escola]="this.escola" *ngIf="escola"></app-escola-qualificacoes>
          <!-- END VISÃO DO ESPECIALISTA -->

          <!-- SAIBA MAIS SOBRE A ESCOLA -->
          <app-escola-mais-sobre [escola]="this.escola" *ngIf="escola"></app-escola-mais-sobre>
          <!-- END SAIBA MAIS SOBRE A ESCOLA -->

          <!-- NÍVEIS DE ENSINO OFERECIDOS -->
          <app-escola-niveis-oferecidos [escola]="this.escola" *ngIf="escola"></app-escola-niveis-oferecidos>
          <!-- END NÍVEIS DE ENSINO OFERECIDOS -->

          <!-- EVENTOS -->
          <app-escola-eventos [escola]="this.escola" *ngIf="escola"></app-escola-eventos>
          <!-- END EVENTOS -->

          <!-- VÍDEOS DA ESCOLA -->
          <app-escola-videos [escola]="this.escola" *ngIf="escola"></app-escola-videos>
          <!-- END VÍDEOS DA ESCOLA -->

          <!-- ADS -->
          <!-- <div class="row mb-5">
            <div class="col-12">
              <img src="images/new/img-Padrao-iAB-Retangulo.jpg" alt="Publicidade" />
            </div>
          </div> -->
          <!-- END ADS -->

          <!-- INFRAESTRUTURA E ACESSIBILIDADE -->
          <app-escola-infra-acessibilidade [escola]="this.escola" *ngIf="escola"></app-escola-infra-acessibilidade>
          <!-- END INFRAESTRUTURA E ACESSIBILIDADE -->

          <!-- PROFESSORES E TIME PEDAGÓGICO -->
          <app-escola-professores-time-pedagogico [escola]="this.escola" *ngIf="escola"></app-escola-professores-time-pedagogico>
          <!-- END PROFESSORES E TIME PEDAGÓGICO  -->

          <!-- IDIOMAS -->
          <app-escola-idiomas [escola]="this.escola" *ngIf="escola"></app-escola-idiomas>
          <!-- END IDIOMAS -->

          <!-- ESPORTES E CULTURA -->
          <app-escola-esportes-cultura [escola]="this.escola" *ngIf="escola"></app-escola-esportes-cultura>
          <!-- END ESPORTES E CULTURA -->
        </div>

        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 order-xxl-2 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1 mb-5">
          <!-- ASIDE OPTIONS -->
          <app-escola-aside *ngIf="escola"
                            [editing]="this.editing"
                            [escola]="this.escola" 
                            [escolaBeneficio]="this.escolaBeneficio" 
                            [requestInfo]="requestInfo"
                            (editAction)="this.editingClick()">
          </app-escola-aside>
          <!-- END ASIDE OPTIONS -->
        </div>
      </div>
      <!-- END ROW -->
    </div>

    <!-- CONFIRA CONTEÚDOS DESTA ESCOLA -->
    <!-- <app-escola-artigos [escola]="this.escola" *ngIf="escola"></app-escola-artigos> -->
    <!-- END CONFIRA CONTEÚDOS DESTA ESCOLA -->

    <!-- PARCERIAS E CERTIFICAÇÕES -->
    <app-escola-parcerias-e-certificacoes [escola]="this.escola" *ngIf="escola"></app-escola-parcerias-e-certificacoes>
    <!-- END PARCERIAS E CERTIFICAÇÕES -->

    <!-- publicidade -->
    <!-- <app-escola-publicidade [escola]="this.escola" *ngIf="escola"></app-escola-publicidade> -->
    <!-- END publicidade -->

    <!-- SOBRE A REGIÃO -->
    <app-escola-sobre-regiao [escola]="this.escola" *ngIf="escola"></app-escola-sobre-regiao>
    <!-- END SOBRE A REGIÃO -->

    <!-- ADS -->
    <!-- <app-escola-ads-slide [escola]="this.escola" *ngIf="escola"></app-escola-ads-slide> -->
    <!-- END ADS -->

    <app-parceiro [flag_exibir_pagina_escola]="true"></app-parceiro>

    <!-- DESBLOQUEIE OUTRAS ESCOLAS -->
    <app-escola-recomendadas [escola]="this.escola" *ngIf="escola"></app-escola-recomendadas>
    <!-- END DESBLOQUEIE OUTRAS ESCOLAS -->
  </main>
  <div *ngIf="false && !this.editing && escola" class="actions edit-fixed" fxHide.lt-sm>
    <div class="row" fxLayout="column" fxLayoutGap="5px">
      <!-- div *ngIf="escola?.can_edit && !editing">
        <button mat-raised-button (click)="editingClick()" [disabled]="this.editing || this.saving">
          <mat-icon inline="true">edit</mat-icon>
          Editar Escola
        </button>
        <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
      </div -->
      <ng-template [ngIf]="editing && this.escola.can_edit">
        <div class="w-100" fxLayout="column" *ngIf="userService.master == true" fxLayoutGap="5px">
          <div fxLayout="column">
            <mat-label>Escola Parceira</mat-label>
            <mat-slide-toggle [(ngModel)]="escola.premium"></mat-slide-toggle>
          </div>
          <div fxLayout="column">
            <mat-label>Escola Ativa</mat-label>
            <mat-slide-toggle [ngModel]="escola.data_desativada == null" (change)="ativaToggle($event)" [matTooltipDisabled]="escola.data_desativada == null" [matTooltip]="'Desativada desde: ' + escola.data_desativada"> </mat-slide-toggle>
          </div>
        </div>
        <button mat-raised-button (click)="saveEdit()">Salvar</button>
        <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
        <button mat-raised-button (click)="cancelEdit()" [disabled]="this.saving">Cancelar</button>
        <!--        <a href="javascript:" (click)="cancelEdit()" class="outros">-->
        <!--          cancelar-->
        <!--        </a>-->
        <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
      </ng-template>
      <!-- div *ngIf="!escola?.can_edit">
        <a href="javascript:" (click)="proprietario()" class="proprietario">Sou proprietário da escola</a>
      </div -->
      <div *ngIf="userService?.beta">
        <button mat-raised-button [matBadge]="this.chatBadge" [matBadgeHidden]="this.chatBadge === null" (click)="this.toggleChat()">
          <mat-icon inline>message</mat-icon>
          Conversar com a Escola
        </button>
        <!--          <ng-template cdkConnectedOverlay-->
        <!--                       [cdkConnectedOverlayWidth]="'50vw'"-->
        <!--                       [cdkConnectedOverlayHeight]="'50vh'"-->
        <!--                       [cdkConnectedOverlayOrigin]="trigger"-->
        <!--                       [cdkConnectedOverlayOpen]="chatVisible"-->
        <!--          >-->
        <app-chatroom style="margin-bottom: 150px" [hidden]="!chatVisible" [roomname]="userService.getCurrentUser().uid + '@' + escola.codigo" (newMessage)="this.newMessage($event)" [escola]="this.escola" class="w-50 h-50 position-fixed bottom-0 end-0"> </app-chatroom>
        <!--        </ng-template>-->
      </div>
      <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
    </div>
  </div>

<ng-template [ngIf]="this.editing">
  <app-escola-detail-edit (callbackEvent)="callBack()"></app-escola-detail-edit>
</ng-template>
