import { AfterViewChecked, Component, Injectable, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { EscolaService } from '../../service/escola.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { UserService } from '../../service/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import User = firebase.User;
import { LoginDialogComponent } from '../auth/login-dialog/login-dialog.component';
import { ChatService } from '../../service/chat.service';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  @Output() onSignOut: EventEmitter<void> = new EventEmitter<void>();
  searchEventTrigger: Observable<void> = new Observable<void>();
  showSearchButton = true;
  manualShowSearchButton = false;
  isSearchAnimation = false;
  topSearch = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  linksStatic: Array<{ icon: string; text: string; callback: any }> = [
    // {icon: 'home', text: 'Minhas Escolas', callback: () => this.minhasCompras()},
    { icon: 'favorite', text: 'Escolas Favoritas', callback: () => this.minhasEscolas() },
    // {icon: 'add', text: 'Avaliações', callback: () => this.avaliacoes()},
    // {icon: 'add', text: 'Cadastrar Escola', callback: () => this.cadastroEscola()},
    { icon: 'question_answer', text: 'Fale com a SchoolAdvisor', callback: () => this.faleConosco() },
    { icon: 'badge', text: 'Perfil de Afinidade', callback: () => this.perfilAfinidade() },
  ];
  links = Object.create(this.linksStatic);
  displayNameInitials: string | null;

  constructor(private matDialog: MatDialog, public userService: UserService, public escolaService: EscolaService, public chatService: ChatService, public auth: AngularFireAuth, public router: Router, private http: HttpClient) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((value) => {
      // console.log('router value: ' + value);
      // console.log('value.url: ' + (value as NavigationEnd).url);
      const url = (value as NavigationEnd).url;

      switch (url) {
        case '/':
          this.topSearch = window.pageYOffset > 50;
          this.showSearchButton = false;
          break;
        case '/mapa':
          this.topSearch = true;
          break;
        default:
          this.topSearch = true;
          this.showSearchButton = true;
          break;
      }
    });

    userService.userDataEvent.subscribe((value) => {
      this.refreshMenu();
    });
  }

  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // console.log('scroll');

    if (!this.isSearchAnimation) {
      this.showSearchButton = !this.isHomeTop();
    }
    this.isSearchAnimation = false;
  }

  private refreshMenu(): void {
    this.links = [];
    if (this.userService.master) {
      this.links.push({ icon: 'person_pin', text: 'Proprietários', callback: () => this.listaProprietario() });
      this.links.push({ icon: 'description', text: 'Relatório Matrículas', callback: () => this.reportMatriculas() });
      this.links.push({ icon: 'collections', text: 'Coleções', callback: () => this.treeColecao() });
    } else {
      for (const item of this.linksStatic) {
        this.links.push(item);
      }
    }
    if (this.userService.userData?.escola && this.userService.beta) {
      const cod_escola_array: number[] = this.userService.userData.escola;
      if (cod_escola_array.length === 1) {
        this.links.push({ icon: 'message', text: 'Chat', callback: () => this.chatLista(cod_escola_array[0]) });
      } else if (cod_escola_array.length > 1) {
        for (const cod_escola of cod_escola_array) {
          this.links.push({
            icon: 'message',
            text: `Chat (INEP ${cod_escola})`,
            callback: () => this.chatLista(cod_escola),
          });
        }
      }
    }
    if (this.userService.userData?.escola || this.userService.master) {
      this.links.push({ icon: 'group', text: 'Parceiros', callback: () => this.listaParceiro() });
      /*this.chatService.getChatRooms(this.userService.userData.escola).then(value => {
        console.log('value: ' + JSON.stringify(value));
        this.links.push({icon: 'message', text: 'Chats', callback: () => this.})
      });*/
    } else {
      this.links.forEach((value1, index) => {
        if (value1.text === 'Parceiros') {
          this.links.splice(index, 1);
        }
      });
    }
    this.matDialog.getDialogById('loginDialog')?.close();
  }

  ngAfterViewChecked(): void {
    // this.topSearch = (this.route.url !== '/');
    // console.log(`floating: ${this.topSearch}`);

    if (this.router.url === '/') {
      this.topSearch = window.pageYOffset < 50;
    } else {
      this.topSearch = false;
    }
  }

  ngOnInit(): void {}

  get user(): User {
    return this.userService.getCurrentUser();
  }

  get photoURL(): string {
    return this.user?.photoURL;
  }

  get email(): string {
    return this.user?.email;
  }

  get displayName(): string {
    return this.user?.displayName;
  }

  public login(): void {
    // console.log('open dialog');
    this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
  }

  checkMapInput(): void {
    const inputElement = document.getElementById('regiaoInput') as HTMLInputElement;
    if (inputElement != null && this.escolaService.regiao.value == null) {
      inputElement.value = '';
    }
  }

  meuPerfil(): Promise<boolean> {
    return this.router.navigate(['perfil']);
  }

  minhasCompras(): Promise<boolean> {
    return this.router.navigate(['compras']);
  }

  minhasEscolas(): Promise<boolean> {
    return this.router.navigate(['minhas_escolas']);
  }

  listaProprietario(): Promise<boolean> {
    return this.router.navigate(['lista_proprietario']);
  }

  listaParceiro(): Promise<boolean> {
    return this.router.navigate(['lista_parceiro']);
  }

  chatLista(cod_escola: number): Promise<boolean> {
    return this.router.navigate(['chats', cod_escola, 'chat']);
  }

  avaliacoes(): Promise<boolean> {
    return this.router.navigate(['reviews']);
  }

  cadastroEscola(): Promise<boolean> {
    return this.router.navigate(['escola']);
  }

  faleConosco(): Promise<boolean> {
    return this.router.navigate(['contato']);
  }

  treeColecao(): Promise<boolean> {
    return this.router.navigate(['tree_colecao']);
  }

  perfilAfinidade(): Promise<boolean> {
    return this.router.navigate(['perfil-afinidade']);
  }

  private reportMatriculas(): Promise<boolean> {
    // const url = 'https://us-central1-firesample-7bd56.cloudfunctions.net/report_escola_matricula';
    const url = 'http://localhost:5001/firesample-7bd56/us-central1/report_escola_matricula';

    this.userService
      .getCurrentUser()
      .getIdToken()
      .then((authToken) => {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + authToken });
        return this.http.post(url, {}, { responseType: 'arraybuffer', headers }).subscribe((response) => {
          const blob = new Blob([response as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const urlResponse = window.URL.createObjectURL(blob);
          const pwa = window.open(urlResponse);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
          }
        });
      });

    return null;
  }

  auth_info(): void {
    firebase
      .functions()
      .httpsCallable('auth_info')({})
      .then((value) => {
        console.log(value);
      });
  }

  removeNivelEnsino(item: string): void {
    const formControl = this.escolaService.nivelEnsino;
    const currentValues: string[] = formControl.value;
    if (currentValues == null) {
      return;
    }
    const index = currentValues.indexOf(item);
    if (index >= 0) {
      currentValues.splice(index, 1);
      formControl.setValue(currentValues);
    }
  }

  getDisplayNameInitials(displayName: string | null): string | null {
    if (!displayName) {
      return null;
    }
    const initialsRegExp: RegExpMatchArray = displayName.match(/\b\w/g) || [];
    const initials = ((initialsRegExp.shift() || '') + (initialsRegExp.pop() || '')).toUpperCase();
    return initials;
  }

  // openProfile(): void {
  //   const dialogRef = this.matDialog.open(UserComponent);
  //   const instance = dialogRef.componentInstance;
  //   instance.onSignOut.pipe(take(1))
  //     .subscribe(() => this.onSignOut.emit()); // propagate the onSignout event
  //   instance.onAccountEdited.pipe(take(1))
  //     .subscribe(
  //       () => this.displayNameInitials = this.getDisplayNameInitials(this.authProcess.user.displayName)
  //     ); // update display name initials?
  // }

  async signOut(): Promise<void> {
    try {
      await this.auth.signOut();
      // Sign-out successful.
      this.onSignOut.emit();
    } catch (e) {
      // An error happened.
      console.error('Ocorreu um erro ao sair do sistema!', e);
    }
  }

  isHome() {
    return this.router.url === '/'; // && (window.pageYOffset < 50);
  }

  isTop() {
    return window.pageYOffset < 150;
  }

  isHomeTop() {
    return this.isHome() && this.isTop();
  }

  toggleSearch() {
    this.showSearchButton = !this.showSearchButton;
    this.manualShowSearchButton = this.showSearchButton;
    // console.log('showSearchButton: ' + this.showSearchButton);
    this.isSearchAnimation = this.showSearchButton;
  }
}
